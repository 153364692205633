<template>
  <CRow>


    <CCol col="12" md="12">
      <CCard v-if="this.validaDocumentos2 == 0 || this.validaDocumentos2 == 2">
        <CCardHeader>
          <CIcon name="cil-justify-center"/> Atención <small> lee cuidadosamente las indicaciónes señaladas</small>
        </CCardHeader>
        <CCardBody>
          <CAlert show color="success">

            <div class="wrapper fadeInDown">
              <!--CAlert color="warning" >
                 <strong>Aviso Importante:</strong> Estimado aspirante, te recordamos que el último día para realizar tu carga de documentos es el día 14 de julio del 2021.
           </CAlert-->
              <p><b>La Universidad Polit&eacute;cnica del Bicentenario a validado tu pago,&nbsp; a continuaci&oacute;n ingresa al siguiente enlace</b>&nbsp;<a href="http://registroenlinea.ceneval.edu.mx/RegistroLinea/indexCerrado.php" target="_blank" rel="noopener" data-saferedirecturl="https://www.google.com/url?q=http://registroenlinea.ceneval.edu.mx/RegistroLinea/indexCerrado.php&amp;source=gmail&amp;ust=1581091637733000&amp;usg=AFQjCNEjpoafEZKbUW9jYZrbKwHyW8FGJg">http://registroenlinea.<wbr />ceneval.edu.mx/RegistroLinea/<wbr />indexCerrado.php</a>&nbsp;</p>
              <p>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;En campo instituci&oacute;n selecciona Universidad Polit&eacute;cnica del Bicentenario.</p>
              <p>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;En el campo matricula captura el folio: <b>{{this.usersData[0].folioCeneval}}</b></p>
              <p>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;En el campo Programa/Carrera selecciona <b>{{this.usersData[0].Carrera}}</b></p>
              <p>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;En el campo Campus/Facultades/Escuelas donde desea inscribirse selecciona Universidad Polit&eacute;cnica del Bicentenario.</p>
              <p>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contestar los cuestionarios.</p>

              <!-- <p>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Descarga e imprime dos veces tu pase de ingreso al examen.</p> -->
              <p>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Imprime y firma tu pase Ceneval de ingreso.</p>

              <p><b>Una vez que cuentes con la siguiente documentaci&oacute;n escaneada en formato PDF (por favor no subas fotografías de celular) ,subela a continuación</b></p>
              <p>1. Copia de acta de nacimiento actualizada (del Año en curso)
                <p><input type="file" accept=".pdf" id="fileActa" ref="fileActa" v-on:change="onChangeActa()"/></p>
              <p>2. copia de&nbsp;CURP
                <p><input type="file" accept=".pdf" id="fileCurp" ref="fileCurp" v-on:change="onChangeCurp()"/></p>
              <p>3. Copia de comprobante de domicilio actualizado (m&aacute;ximo&nbsp;3 meses)
                <p><input type="file" accept=".pdf" id="fileComprobante" ref="fileComprobante" v-on:change="onChangeComprobante()"/></p>
              <p>4. Copia de certificado de terminaci&oacute;n de estudios legalizado en caso de ser necesario, si a&uacute;n no cuentas con el documento ingresar constancia original de estudios con promedio general y grado que cursa, as&iacute; como la modalidad de estudios ejemplo: bachillerato cuatrimestral, semestral etc. Debe contar con sellos y firma de la autoridad responsable
                <p><input type="file" accept=".pdf" id="fileCertificado" ref="fileCertificado" v-on:change="onChangeCertificado()"/></p>
              <p>5. Recibo de pago de Ficha.
                <p><input type="file" accept=".pdf" id="filePago" ref="filePago" v-on:change="onChangePago()"/></p>
              <p>6. Pase de examen CENEVAL firmado por el sustentante.
                <p><input type="file" accept=".pdf" id="fileCeneval" ref="fileCeneval" v-on:change="onChangeCeneval()"/></p>
              <p>&nbsp;</p>
              <p>La universidad validará tu expediente de ficha y en caso de cumplir con todos los requisitos te enviará la ficha de examen de admisión y la información necesaria para el día de la aplicación.</p>
              <CButton  :disabled='isDisabled' size="sm" color="primary" @click="uploadAll()">{{txt_btn}}</CButton>
            </div>
            <hr>
            <p class="mb-0">

            </p>
          </CAlert>
        </CCardBody>
      </CCard>
      <CCard v-if="this.validaDocumentos2 == 1">
        <CCardHeader>
          <strong>Expediente en revision</strong>

        </CCardHeader>

        <CCardBody>
          <!-- Bootstrap Vue has some problems with Inline forms that's why we use some standard bootstrap classes -->
          <CForm inline>
            <CRow>
              <CCol sm="12">
                <small> su expediente esta en revisión, se le notificara por correo en caso de ser aprobado/rechazado
                  &nbsp;</small>
                <br/>
              </CCol>
              <CCol sm="12" style="text-align: center">
                <p>
                  <CButton color="success" @click="salir()" block>Salir</CButton>
                </p>

              </CCol>

            </CRow>


          </CForm>
        </CCardBody>
        <CCardFooter>
        </CCardFooter>
      </CCard>
    </CCol>

  </CRow>
</template>


<script>
export default {
  name: 'Alerts',
  data () {
    return {
      activo_btn:true,
      txt_btn: "Enviar Documentos",
      id:localStorage.getItem('id'),
      dismissSecs: 10,
      dismissCountDown: 10,
      alert1: true,
      alert2: true,
      usersData: [],
      fileActa: '',
      fileCurp: '',
      fileComprobante: '',
      fileCertificado: '',
      filePago: '',
      fileCeneval: '',
      validaDocumentos2: localStorage.getItem('validaDocumentos2')
    }
  },
  created(){
    this.get_referencias();
  },
  computed:{
    isDisabled: function(){
      return !this.activo_btn;
    }
  },
  methods: {
    //acta,curp,comprobante,certificado,pago,ceneval
    onChangeActa(){
      this.fileActa = this.$refs.fileActa.files[0];
    },
    onChangeCurp(){
      this.fileCurp = this.$refs.fileCurp.files[0];
    },
    onChangeComprobante(){
      this.fileComprobante = this.$refs.fileComprobante.files[0];
    },
    onChangeCertificado(){
      this.fileCertificado = this.$refs.fileCertificado.files[0];
    },
    onChangePago(){
      this.filePago = this.$refs.filePago.files[0];
    },
    onChangeCeneval(){
      this.fileCeneval = this.$refs.fileCeneval.files[0];
    },
    uploadAll(){
      this.activo_btn = false;
              this.txt_btn =  "Enviando...";
      let formData = new FormData();
      formData.append('fileActa', this.fileActa);
      formData.append('fileCurp', this.fileCurp);
      formData.append('fileComprobante', this.fileComprobante);
      formData.append('fileCertificado', this.fileCertificado);

      formData.append('filePago', this.filePago);
      formData.append('fileCeneval', this.fileCeneval);

      this.$http.post('https://cdn.upbicentenario.edu.mx/api/uploadAdmision2/' + this.id,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
      ).then(response => {

        if(response.data.mensaje == '1'){
          this.alerta_txt = "se han guardado los datos correctamente";
          this.warningModal = true;
          this.validaDocumentos2 = 1
        }else{
          this.alerta_txt = response.data.mensaje;
          this.warningModal = true;
        }
      });


    },
    get_referencias(){
      this.$http.get("referenciasCandidato/" + this.id).then(
              response => {
                // console.log(response.data)
                this.usersData = response.data.referencias;
                //console.log(this.kardex);
              },
              error => {
                this.StepError(error);
              }
      );
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    showDismissibleAlerts () {
      ['alert1', 'alert2'].forEach(alert => this[alert] = true)
    },
    salir(){
      this.$router.push('/pages/login')
    },
  }
}
</script>
